





















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Trade from '@/store/entities/registryoffices/trade';
import ListBase from '@/lib/listbase';

@Component({
        components: { FormBase, ModalView },
        watch: {
            value: function (val) {
                let obj = (this as any);
                obj.modalShow = val;
                if (val) {
                    obj.initForm();
                } else
                    this.$emit('input', false);
            },
            modalShow: function (val) {
                if (!val) this.$emit('input', false);
            }
        }
})

export default class TradeForm extends ListBase {
        entity: string = 'trade';
        @Prop({ type: Boolean, default: false }) value: boolean;
        trade: Trade = new Trade();
        modalShow: boolean = false;
        modalSetting: any = {
            entity: "trade",
            titleFull: 'Mesa de Partes',
            saveText: 'Actualizar',
            width: '1000px',
            showForm: false,
            disabledSave: false,
        };

        get loading() {
            return this.$store.state.trade.loading;
        };

        get current_state() {
            if (this.trade == undefined || this.trade.state_id == undefined) return {};
            return Util.abp.list.getItem(this.trade.state_id);
        }

        get states() {
            return Util.abp.list.getList('E_APPLICATION');
        }

        items = [
            this.gItem({ type: 'price', prop: 'amount', label: 'Monto', size: 4 }),
            this.gItem({ type: 'textarea', prop: 'comments', label: 'Comentarios', size: 12, rows: 3 }),
        ];

        getInitials(string) {
            return Util.getInitials(string);
        }

        changeState(item) {
            this.trade.state_id = item.id;
        }

        showHistory() {
            this.$emit('show-history', this.trade);
        }

        showImage() {
            this.$emit('show-image', this.trade);
        }

        initForm() {
            //this.trade = Util.extend(true, {}, this.$store.state.trade.editModel);
            //let type = Util.abp.list.getItem(this.trade.);
            //this.modalSetting.titleFull = type.name;
            this.modalSetting.disabledSave = !this.canEdit();
        }

        async save() {
            if ((this.$refs.entityForm1 as any).validate()) {
                let model = Util.extend(true, {}, this.trade);

                await this.$store.dispatch({
                    type: this.entity + '/' + (this.trade.id > 0 ? 'update' : 'create'),
                    data: model
                });
                this.$emit('save-success');
                this.cancel();
                Util.abp.notify.success(this, 'Solicitud actualizada!');
            }
        }

        cancel() {
            this.$emit('input', false);
            (this.$refs.entityForm1 as any).reset();
        }

        mounted() {

        }

        created() {
        }
}
