




















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import TradeView from './view.vue';
import History from '@/views/_shared/history.vue';
import ImageModal from '@/components/image.vue';

class PageTradeRequest extends PageRequest {
    lawyer: number = null;
    state: number = null;
    date_from: string = new Date().toISOString().substr(0, 8) + '01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ListView, FormBase, TradeView, History, ImageModal }
})
export default class Trades extends ListBase {
    entity: string = 'trade';
    pagerequest: PageTradeRequest = new PageTradeRequest();
    filterForm: boolean = true;
    hisModalShow: boolean = false;
    imgModalShow: boolean = false;
    imgUrl: string = '';

    filterItems = [
        this.gItem({ type: 'object', prop: 'lawyer', label: 'Buscar Agremiado por Nombre, Matricula o Documento', itemText: 'description_full', autocomplete: true, searchLen: 2, size: 6, returnObject: false, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', size: 2, generalType: 'E_APPLICATION', hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Solicitudes Desde', size: 2, hideDetails: true }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true }),

    ]

    columns = [
        this.gColO({ type: 'text', value: 'type_id', text: 'Tipo', generalType: true }),
        this.gColO({ type: 'text', value: 'lawyer.code', text: 'Matricula', main: true }),
        this.gCol('lawyer.document', 'Documento', 'text'),
        this.gCol('lawyer.fullname', 'Agremiado', 'text'),
        this.gCol('trade_date', 'Fecha de Solicitud', 'text'),
        this.gCol('amount', 'Monto', 'price'),
        this.gColO({ type: 'button', value: 'filename', text: 'Formulario', icon: 'attach_file', width: '50', color: 'success', typeIcon: true, condition: 'has_file', conditionVal: true, onclick: this.showImage }),
        this.gColO({ type: 'chip', value: 'state_id', text: 'Estado', color: 'extra', state: true, generalType: true, onclick: this.showHistory }),
        this.defaultActions('view', 50)
    ];

    view() {
        this.modalShow = !this.modalShow;
    }

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    showHistory(item) {
        this.$store.commit('trade/view', item);
        this.hisModalShow = !this.hisModalShow;
    }

    showImage(item) {
        this.imgUrl = item.image_url;
        this.imgModalShow = !this.imgModalShow;
    }

    async created() {
        //this.getpage();
    }
}